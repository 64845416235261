import React from "react";
import { Tab, Nav, Container, Col, Row } from "react-bootstrap";
import FeatureTabPenLayout from "./Components/FeatureTabPenLayout";
import FeatureImage from "./Components/FeatureImages";
import Feature from "./style";
import featureSectionData from "~data/project/features";
import FeatureWidgetsBlock from "./Components/FeatureWidgetsBlock";
import { TabTitle } from "./Components/FeatureTabPenLayout/style";
import { SuperTag } from "~components";
export default function FeatureSection() {
  return (
    <Feature>
      <TabTitle as="h2" fontColor="#fff" mb="40px" weight="500">
          <SuperTag value={"Sistema de Cobranza Automatizado"} />
      </TabTitle>
      <Container className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Feature.Tab>
            <Row className="justify-content-center">
              <Col className="col-xl-7 col-lg-8 col-md-10">

                  <Nav className="nav nav-tabs" id="myTab" role="tablist">
                    <Nav.Link eventKey="first" className="nav-link">
                      Desembolso del Préstamo
                    </Nav.Link>

                    <Nav.Link eventKey="second" className="nav-link">
                      Plataforma de Pagos en Línea
                    </Nav.Link>
                  </Nav>
              </Col>
            </Row>
            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="first">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.DesembolsoDelPrestamo}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle1}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.PagoEnlinea}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle2}
                />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="third">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.tabWidget1}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle3}
                />
              </Tab.Pane> */}
            </Tab.Content>
          </Feature.Tab>
        </Tab.Container>
      </Container>
    </Feature>
  );
}
